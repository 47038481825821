@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

@supports (font-variation-settings: normal) {
  html {
    font-family: 'Inter var', sans-serif;
  }
}

html {
  overflow-y: auto;
}

body {
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-family: 'Inter', sans-serif;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
  background-color: #fafafa;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}
.ant-table-tbody > tr > td,
.ant-table-thead > tr > th {
  border: none !important;
}
/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */

/* total width */
*::-webkit-scrollbar {
  background-color: transparent;
  width: 16px;
}

/* background of the scrollbar except button or resizer */
*::-webkit-scrollbar-track {
  background-color: transparent;
}

*::-webkit-scrollbar-track:hover {
  background-color: transparent;
}

/* scrollbar itself */
*::-webkit-scrollbar-thumb {
  background-color: #e9eaf1;
  border-radius: 16px;
  border: 5px solid #fff;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5;
  border: 4px solid #fff;
}

/* set button(top and bottom of the scrollbar) */
*::-webkit-scrollbar-button {
  display: none;
}

@media all and (max-width: 768px) {
  *::-webkit-scrollbar {
    width: initial;
  }

  *::-webkit-scrollbar-thumb {
    background-color: transparent;
    border: 5px solid transparent;
  }
}

/**
 * Navlink
 */
.active-nav-link span {
  color: #2c98f0;
}

.active-nav-link svg path {
  fill: #2c98f0;
}

/**
 * Page transitions
 */

.fade-enter {
  opacity: 0;
  z-index: 1;
  height: 0;
}

.fade-enter.fade-enter-active {
  height: auto;
  opacity: 1;
  transition: opacity 250ms ease-in;
}

.fade-exit.fade-exit-active {
  height: 0;
  overflow: hidden;
  min-height: 0;
}

input,
textarea {
  -webkit-user-select: text; /* Chrome, Opera, Safari */
  -moz-user-select: text; /* Firefox 2+ */
  -ms-user-select: text; /* IE 10+ */
  user-select: text; /* Standard syntax */
}

.antd-upload-input,
.antd-upload-input > .ant-upload {
  width: 100%;
  height: 100%;
  margin: 0;
  border: 0;
  background: #f9fafc;
  display: flex;
  flex: 1;
}

@keyframes locationButtonpulse {
  0% {
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(255, 208, 55, 0.7);
    /* background-color: rgba(255, 208, 55, 0.7); */
  }

  70% {
    transform: scale(1.1);
    box-shadow: 0 0 0 10px rgba(255, 208, 55, 0);
    /* background-color: rgba(255, 208, 55, 1); */
  }

  100% {
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(255, 208, 55, 0);
    /* background-color: rgba(255, 208, 55, 0.5); */
  }
}

@keyframes confirmOrderpulse {
  0% {
    background-color: rgba(46, 204, 113, 1);
  }

  50% {
    background-color: rgba(46, 204, 113, 0.5);
  }

  100% {
    background-color: rgba(46, 204, 113, 1);
  }
}

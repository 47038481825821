div > div[name='StarButton'] > svg {
  stroke: #bfcadb;
  transition: stroke 350ms;
}
div > div[name='StarButton']:hover > svg {
  stroke: #ffd036;
}

div:hover > div[name='StarButton'] > svg {
  stroke: #ffd036;
}

div > div[name='StarButton']:hover ~ div > svg {
  stroke: #bfcadb;
}

div[name='StarButton']:not(:last-child) {
  margin-right: 20px;
  position: relative;
}

div > div[name='StarButton']:after {
  content: '';
  position: absolute;

  top: 0;
  right: 0;
  bottom: 0;
  left: 25px;

  height: 25px;
  width: 20px;
}
html,
body {
  overflow: hidden !important;
  position: fixed;
}

/* #root, #root>div {
    height: 100%;
    overflow: scroll;
} */

.ant-input-number-handler-wrap {
  -webkit-appearance: none !important;
  display: none !important;
  margin: 0 !important;
}

.ant-input-number-input {
  width: 40px !important;
  height: 40px !important;
  padding: 0 !important;
  text-align: center;
}

.ant-calendar-picker-container {
  z-index: 9999999;
}

.ant-calendar-picker-input {
  border: 0;
  border-radius: 0;
  height: 43px;
  border-bottom: 1px solid #e1e6ec;
}

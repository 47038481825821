@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);
.rowBackground:nth-child(odd) {
    background: #F9FAFC;
    margin: 4px 0;
}
.rowBackground:nth-child(even) {
    background: #FFFFFF;
    margin: 4px 0;
}
.rowBackground:last-child {
    margin-bottom: 0;
}
.pac-container {
  z-index: 9997;
}

.pac-container:after {
  background-image: none !important;
  height: 0px;
}

.ant-input-number-handler-wrap {
  -webkit-appearance: none !important;
  display: none !important;
  margin: 0 !important;
}

.ant-input-number-input {
  width: 40px !important;
  height: 40px !important;
  padding: 0 !important;
  text-align: center;
}

.ant-carousel .slick-slide {
  overflow: hidden;
}

.ant-carousel .slick-slide h3 {
  color: #fff;
}

.ant-carousel .slick-dots li button {
  background: #2a2e45 !important;
}
.ant-carousel > .slick-dots > li.slick-active button {
  background: #2a2e45 !important;
}

.ant-carousel .slick-dots-bottom {
  bottom: -10px;
}

/* @media all and (max-width: 500px) {
  .ant-carousel .slick-dots-bottom {
    bottom: 20vh;
  }
} */

.table-row-light, .ant-table-thead > tr > th {
    background-color: #FFFFFF !important;
}
.table-row-dark {
    background-color: #F9F9F9;
}

.table-row-light > td > span > button:first-child{
    background-color: #F9FAFC !important;
}

.table-row-dark > td > span > button:first-child{
    background-color: #FFFFFF !important;
}

.ant-table-thead > tr > th .ant-table-header-column {
    width: 100%;
}

div > .ant-table-wrapper > .ant-spin-nested-loading > .ant-spin-container > .ant-table > .ant-table-content > .ant-table-footer{
    padding: 0;
    padding-bottom: 12px;
}
html,
body {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

body {
  position: relative;
}

::-webkit-scrollbar {
  display: none !important;
}

.drawerContainerElementClass {
  background-color: rgba(22, 25, 41, 0.4) !important;
  overflow: hidden !important;
  z-index: 9997 !important;
}

.drawerModalElementClass {
  /* margin-top: auto;
  margin-bottom: 60px; */
  width: 100vw;
}

.PhoneInput {
  font-size: 14px;
  line-height: 22px;
  color: #5c677b;
  background: #f9fafc;
  height: 52px;
}
.PhoneInputCountry {
  margin: 0 20px;
}
.PhoneInputCountryIcon,
.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
  border: 0 !important;
  box-shadow: none;
  background-color: unset;
}
.PhoneInputCountrySelectArrow {
  margin-left: 16px;
}
.PhoneInputCountryIcon,
.PhoneInputCountryIcon--border {
  box-shadow: none;
  background-color: unset;
  border: 0 !important;
}
.PhoneInputInput--focus,
.PhoneInputInput {
  font-size: 14px;
  line-height: 22px;
  color: #5c677b;
  background: #f9fafc;
  height: 50px;
  border: 0;
  outline: none;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

::-webkit-scrollbar {
    display: block !important; 
}

.ant-calendar-picker-container {
  z-index: 9999999;
}

.ant-calendar-picker-input {
  border: 0;
  border-radius: 0;
  height: 43px;
  border-bottom: 1px solid #e1e6ec;
}

div > div[name='StarButton'] > svg {
  stroke: #bfcadb;
  transition: stroke 350ms;
}
div > div[name='StarButton']:hover > svg {
  stroke: #ffd036;
}

div:hover > div[name='StarButton'] > svg {
  stroke: #ffd036;
}

div > div[name='StarButton']:hover ~ div > svg {
  stroke: #bfcadb;
}

div[name='StarButton']:not(:last-child) {
  margin-right: 20px;
  position: relative;
}

div > div[name='StarButton']:after {
  content: '';
  position: absolute;

  top: 0;
  right: 0;
  bottom: 0;
  left: 25px;

  height: 25px;
  width: 20px;
}
html,
body {
  overflow: hidden !important;
  position: fixed;
}

/* #root, #root>div {
    height: 100%;
    overflow: scroll;
} */

html,
body {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

body {
  position: relative;
}

::-webkit-scrollbar {
  display: none !important;
}

.drawerContainerElementClass {
  background-color: rgba(22, 25, 41, 0.4) !important;
  overflow: hidden !important;
  z-index: 9997 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: flex-end !important;
}

.drawerModalElementClass {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.PhoneInput {
  font-size: 14px;
  line-height: 22px;
  color: #5c677b;
  background: #f9fafc;
  height: 52px;
  width: 100%;
}
.PhoneInputCountry {
  margin: 0 20px;
}
.PhoneInputCountryIcon,
.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
  border: 0 !important;
  box-shadow: none;
  background-color: unset;
}
.PhoneInputCountrySelectArrow {
  margin-left: 16px;
}
.PhoneInputCountryIcon,
.PhoneInputCountryIcon--border {
  box-shadow: none;
  background-color: unset;
  border: 0 !important;
}
.PhoneInputInput--focus,
.PhoneInputInput {
  font-size: 14px;
  line-height: 22px;
  color: #5c677b;
  background: #f9fafc;
  height: 50px;
  border: 0;
  outline: none;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.ant-drawer-body {
  padding: 0;
}

@supports (font-variation-settings: normal) {
  html {
    font-family: 'Inter var', sans-serif;
  }
}

html {
  overflow-y: auto;
}

body {
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  -webkit-user-select: none;
          user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-family: 'Inter', sans-serif;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
  background-color: #fafafa;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}
.ant-table-tbody > tr > td,
.ant-table-thead > tr > th {
  border: none !important;
}
/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */

/* total width */
*::-webkit-scrollbar {
  background-color: transparent;
  width: 16px;
}

/* background of the scrollbar except button or resizer */
*::-webkit-scrollbar-track {
  background-color: transparent;
}

*::-webkit-scrollbar-track:hover {
  background-color: transparent;
}

/* scrollbar itself */
*::-webkit-scrollbar-thumb {
  background-color: #e9eaf1;
  border-radius: 16px;
  border: 5px solid #fff;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5;
  border: 4px solid #fff;
}

/* set button(top and bottom of the scrollbar) */
*::-webkit-scrollbar-button {
  display: none;
}

@media all and (max-width: 768px) {
  *::-webkit-scrollbar {
    width: initial;
  }

  *::-webkit-scrollbar-thumb {
    background-color: transparent;
    border: 5px solid transparent;
  }
}

/**
 * Navlink
 */
.active-nav-link span {
  color: #2c98f0;
}

.active-nav-link svg path {
  fill: #2c98f0;
}

/**
 * Page transitions
 */

.fade-enter {
  opacity: 0;
  z-index: 1;
  height: 0;
}

.fade-enter.fade-enter-active {
  height: auto;
  opacity: 1;
  transition: opacity 250ms ease-in;
}

.fade-exit.fade-exit-active {
  height: 0;
  overflow: hidden;
  min-height: 0;
}

input,
textarea {
  -webkit-user-select: text; /* Chrome, Opera, Safari */ /* Firefox 2+ */ /* IE 10+ */
  user-select: text; /* Standard syntax */
}

.antd-upload-input,
.antd-upload-input > .ant-upload {
  width: 100%;
  height: 100%;
  margin: 0;
  border: 0;
  background: #f9fafc;
  display: flex;
  flex: 1 1;
}

@-webkit-keyframes locationButtonpulse {
  0% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(255, 208, 55, 0.7);
    /* background-color: rgba(255, 208, 55, 0.7); */
  }

  70% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    box-shadow: 0 0 0 10px rgba(255, 208, 55, 0);
    /* background-color: rgba(255, 208, 55, 1); */
  }

  100% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(255, 208, 55, 0);
    /* background-color: rgba(255, 208, 55, 0.5); */
  }
}

@keyframes locationButtonpulse {
  0% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(255, 208, 55, 0.7);
    /* background-color: rgba(255, 208, 55, 0.7); */
  }

  70% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    box-shadow: 0 0 0 10px rgba(255, 208, 55, 0);
    /* background-color: rgba(255, 208, 55, 1); */
  }

  100% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(255, 208, 55, 0);
    /* background-color: rgba(255, 208, 55, 0.5); */
  }
}

@-webkit-keyframes confirmOrderpulse {
  0% {
    background-color: rgba(46, 204, 113, 1);
  }

  50% {
    background-color: rgba(46, 204, 113, 0.5);
  }

  100% {
    background-color: rgba(46, 204, 113, 1);
  }
}

@keyframes confirmOrderpulse {
  0% {
    background-color: rgba(46, 204, 113, 1);
  }

  50% {
    background-color: rgba(46, 204, 113, 0.5);
  }

  100% {
    background-color: rgba(46, 204, 113, 1);
  }
}


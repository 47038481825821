.ant-carousel .slick-slide {
  overflow: hidden;
}

.ant-carousel .slick-slide h3 {
  color: #fff;
}

.ant-carousel .slick-dots li button {
  background: #2a2e45 !important;
}
.ant-carousel > .slick-dots > li.slick-active button {
  background: #2a2e45 !important;
}

.ant-carousel .slick-dots-bottom {
  bottom: -10px;
}

/* @media all and (max-width: 500px) {
  .ant-carousel .slick-dots-bottom {
    bottom: 20vh;
  }
} */
